<template>
  <div class="modalPopup counter_modal">
    <div class="modal_header">
      <h2 class="title">
        계수기 연동
      </h2>
      <button type="button" class="modal_close" @click="isModalClose"></button>
    </div>
    <div class="modal_body">
      <div>
        <h4>통과수량</h4>
        <ul>
          <li
            class="selected_item"
            v-for="(pc, index) in pass_counter"
            :key="index"
          >
            <span>{{ findInfoFromId(plc_sensors, pc.counter_id).name }}</span
            ><button
              type="button"
              class="delete_item"
              @click.prevent.stop="deleteCounterValue(pc)"
            ></button>
          </li>
        </ul>
        <my-local-selectric
          :id="'passcount_selectric'"
          :watch="null"
          :options="plc_counter_option"
          @changeValue="setPassCounterPlc($event)"
        ></my-local-selectric>
      </div>
      <div>
        <h4>불량수량</h4>
        <ul>
          <li
            class="selected_item default"
            v-for="(pc, index) in fail_counter"
            :key="index"
          >
            <span>{{ findInfoFromId(plc_sensors, pc.counter_id).name }}</span
            ><button
              type="button"
              class="delete_item"
              @click.prevent.stop="deleteCounterValue(pc)"
            ></button>
          </li>
        </ul>
        <my-local-selectric
          :id="'failcount_selectric'"
          :watch="null"
          :options="plc_counter_option"
          @changeValue="setFailCounterPlc($event)"
        ></my-local-selectric>
      </div>
    </div>
    <div class="modal_footer">
      <button type="button" class="btn_sub2" @click="submit">
        결정
      </button>
    </div>
  </div>
</template>

<script>
import FETCH_MIXIN from '@/mixins/fetch';
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';
import MyLocalSelectric from '@/layouts/components/MyLocalSelectric';
import { mapGetters } from 'vuex';

export default {
  mixins: [FETCH_MIXIN, DRAG_MODAL_MIXIN],
  components: {
    MyLocalSelectric,
  },
  props: {
    managementData: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      counter_plc_sensors: [],
      counter_value: [],
    };
  },
  computed: {
    ...mapGetters({
      plc_sensors: 'getPlcSensor',
    }),
    pass_counter() {
      return this.lodash
        .clonedeep(this.counter_value)
        .filter(x => !x.poor_counter_yn);
    },
    fail_counter() {
      return this.lodash
        .clonedeep(this.counter_value)
        .filter(x => x.poor_counter_yn);
    },
    plc_counter_option() {
      if (this.plc_sensors.length < 1) {
        return [
          {
            label: '선택',
            value: null,
          },
        ];
      } else {
        let counters = this.lodash
          .clonedeep(this.plc_sensors)
          .filter(x => x.plc_sensor_type_id == 3);
        if (counters.length < 1) {
          return [
            {
              label: '선택',
              value: null,
            },
          ];
        } else {
          counters = counters
            .map(x => {
              x.value = x.id;
              x.label = x.name;
              return x;
            })
            .filter(
              x => !this.counter_value.map(y => y.counter_id).includes(x.value),
            );
          return [{ value: null, label: '선택' }].concat(counters);
        }
      }
    },
  },
  methods: {
    deleteCounterValue(e) {
      const index = this.counter_value.findIndex(
        x => x.counter_id == e.counter_id,
      );
      this.counter_value.splice(index, 1);
    },
    isModalClose() {
      this.$emit('onclose');
    },
    setPassCounterPlc(e) {
      if (e != null) {
        this.counter_value.push({ counter_id: e, poor_counter_yn: false });
      }
    },
    setFailCounterPlc(e) {
      if (e != null) {
        this.counter_value.push({ counter_id: e, poor_counter_yn: true });
      }
    },
    submit() {
      this.$emit('onSubmit', this.counter_value);
    },
  },
  async created() {
    if (this.plc_sensors.length < 1) {
      await this.FETCH('FETCH_PLC_SENSOR', 'PLC 센서');
    }
    console.log(this.managementData);
    if (
      this.managementData.counter_value != undefined &&
      this.managementData.counter_value != null
    ) {
      this.counter_value = this.lodash.clonedeep(
        this.managementData.counter_value,
      );
    }
  },
};
</script>

<style lang="scss" scoped></style>
